import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="bg-[url('https://static.pexels.com/photos/6526/sea-beach-holiday-vacation-large.jpg')] bg-cover p-[5rem]">
        
    <h2 className="text-center teamtitle">
      Our Team
      </h2><br/>
      
      
      <p className="text-center teamp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
      
      <div className="flex items-center justify-center">
      <button className="teambtn bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
      Explore More
            </button>
            </div>
            </div>
<br/>

<Container>

<h2 className="text-center teamtitle">
Our senior management
      </h2><br/>
<div className="grid  sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-7">
    
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    {/* <div className=" teamcard w-full lg:max-w-full lg:flex">
      <div className=" bg-[url('https://static.pexels.com/photos/6526/sea-beach-holiday-vacation-large.jpg')] bg-cover p-[5rem]   lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden "  title="Mountain">
      </div>
      <div className="  rounded-b lg:rounded-b-none bg-white bg-opacity-20 backdrop-blur-lg rounded drop-shadow-lg lg:rounded-r p-4 flex flex-col justify-between leading-normal">
        <div className="">
          
          <div className="text-gray-900 font-bold ">Raghuraman
Ramamurthy</div>
          <p className="text-gray-700 text-base">Founder & CEO</p>
        </div>
        
          
          <div className="text-sm">
          <button className="teambtn bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
          Visit Profile
            </button>
          
        </div>
      </div>
    </div> */}
   <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
  </div>
 
  </Container>
  <div className="bgtrose mt-[-45rem]">

  </div>

<br/>

  <Container>
  <h2 className="text-center teamtitle">
  Our Management Team
      </h2><br/>

     
<div className="grid  sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
    
   
<div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
 
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
    <div className=" teamcard w-full ">
    <img src="/images/raghu.png"/>
    
    </div>
  </div>
 
  </Container>
  <div className="bgtrose1 mt-[-25rem]">

  </div>




  
  </Layout>
)

export default IndexPage
